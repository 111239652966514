/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { store } from "../store";
import {
  endBotResponse,
  selectSessionId,
  selectUserId,
  setAgentId,
  setPublicKey,
  setSessionId,
  startBotResponse,
  streamBotResponse,
} from "store/messages";
import ObjectID from "bson-objectid";
import { t } from "i18next";
import { getUserProps } from "hooks/useUserId";
import { v4 as uuidv4 } from "uuid";
import { setUserId } from "store/widget";

const props = getUserProps();
store.dispatch(setSessionId(props.sessionId || uuidv4()));
store.dispatch(setAgentId(props.agentId));
store.dispatch(setPublicKey(props.userToken));
store.dispatch(setUserId(props.userId || uuidv4()));
console.log(props);

export const createUserMessage = (message: string) => {
  return {
    text: message,
    sender: "USER",
    messageType: "text",
    ts: new Date().toISOString(),
  };
};

export const getBotResponseV2 = async ({ message, publicKey, agentId }: { message?: string, publicKey: string | null, agentId: string | null  }) => {
  const state = store.getState();
  const sessionId = selectSessionId(state);
  const userId = selectUserId(state);
  const botMessageId = String(ObjectID());
  const stream = true;
  
  try {
    store.dispatch(startBotResponse());
    
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/completions?agentId=${agentId}&publicKey=${publicKey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ff60756d-d0ce-42f9-9150-467660d03680`,
        },
        body: JSON.stringify({
          messages: [
            {
              role: "user",
              content: message,
            },
          ],
          session_id: sessionId,
          trace_user_id: userId,
          trace_id: uuidv4(),
          agentId: agentId,
          stream: stream,
        }),
      }
    );
    
    if (!response.body) {
      return Promise.resolve(t("genericError"));
    }
    
    const reader = response.body.getReader();
    let partialResponse = "";
    let lastMessage = "";
    
    while (true) {
      const { done, value } = await reader.read();
      
      if (done) {
        break;
      }
      
      partialResponse += new TextDecoder("utf-8").decode(value);
      console.log(partialResponse)
      
      // Split the response by '}' and filter out any empty strings
      const jsonObjects = partialResponse
        .split("}")
        .filter((str) => str.trim() !== "");
      
      // Parse each JSON object and extract the 'content' field
      const messages = jsonObjects.map((str) => {
        return str;
      });
      
      // Get the last message
      const currentMessage = messages[messages.length - 1];
      
      // If the last message is different from the current message, it means we have a new message
      if (lastMessage !== currentMessage) {
        lastMessage = currentMessage;
        
        store.dispatch(
          streamBotResponse({
            botMessageId,
            partialResponse: currentMessage,
          })
        );
      }
    }
    
    store.dispatch(
      endBotResponse({
        botMessageId,
        botResponse: lastMessage,
      })
    );
    
    return Promise.resolve(lastMessage);
  } catch (error) {
    console.log("error occurred fetching bot response", error);
    return Promise.resolve(t("genericError"));
  }
};
