import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'

import { XMarkIcon } from '@heroicons/react/24/solid'
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'
import { useAppContext } from 'hooks/useAppContext'
import { selectToggleWidget, setToggleWidget } from 'store/widget'
import {
  MessageCircleMore,
  MessageCircle,
  MessageSquareMore,
  MessageSquareDot,
  MessageSquareText,
  MessageSquare,
} from 'lucide-react';
import {useEffect} from "react";

export const WidgetLauncher = () => {
  const dispatch = useDispatch()
  const toggleWidget = useSelector(selectToggleWidget)
  const appContext = useAppContext()
  const { widgetColor, textColor } = appContext
  
  const getIconComponent = () => {
    switch (appContext.chatIcon) {
      case 'default':
        return <MessageCircleMore className="tw-h-10 tw-w-10"/>;
      case 'circle':
        return <MessageCircle className="tw-h-10 tw-w-10"/>;
      case 'squareMore':
        return <MessageSquareMore className="tw-h-10 tw-w-10"/>;
      case 'squareDot':
        return <MessageSquareDot className="tw-h-10 tw-w-10"/>;
      case 'squareText':
        return <MessageSquareText className="tw-h-10 tw-w-10"/>;
      case 'square':
        return <MessageSquare className="tw-h-10 tw-w-10"/>;
      default:
        return <ChatBubbleOvalLeftEllipsisIcon className="tw-h-10 tw-w-10" />;
    }
  };
  
  useEffect(() => {
    const chatbotButton = document.getElementById('chatbot-button');
    
    if (chatbotButton) {
      const handleClick = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        const newState = !toggleWidget;
        console.log('newState', newState)
        //dispatch(setToggleWidget(newState));
        window.parent.postMessage({
          type: 'chatbotStateChange',
          isClosed: !newState
        }, '*');
        
        window.parent.postMessage({
          type: 'toggleWidget',
          toggleWidget: toggleWidget
        }, '*');
      };

      chatbotButton.addEventListener('click', handleClick);

      return () => {
        chatbotButton.removeEventListener('click', handleClick);
      };
    }
  }, [dispatch, toggleWidget]);
  
  return (
    <motion.div
      animate={{
        scale: [0, 1.1, 1],
      }}
      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
      className={`tw-fixed tw-right-5 tw-bottom-2 tw-mr-2 tw-inline-flex tw-cursor-default tw-items-center tw-rounded-full tw-p-2 tw-text-center tw-text-sm tw-font-medium tw-text-white tw-xs:right-0`}
      style={{ backgroundColor: widgetColor, color: textColor }}
      id='chatbot-button'
      onClick={(e: { preventDefault: () => void }) => {
        e.preventDefault();
        dispatch(setToggleWidget(!toggleWidget));
      }}
    >
      <AnimatePresence>
        {toggleWidget ? (
          <motion.div
            animate={{
              rotate: [0, 90],
            }}
          >
            <XMarkIcon className="tw-h-12 tw-w-12" />
          </motion.div>
        ) : (
          <motion.div animate={{ opacity: 1 }}>
            {getIconComponent()}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}
