import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectSessionId, setWelcomeMessage } from 'store/messages'
import { WidgetHeader } from 'components/WidgetHeader'
import { WidgetMessages } from 'components/WidgetMessages'
import { WidgetKeypad } from 'components/WidgetKeypad'
import { WidgetLauncher } from 'components/WidgetLauncher'
import { getGuestChat } from 'api'
import clsx from 'clsx'
import { useGetBotMode } from 'hooks/useGetBotMode'
import { useToggleWidget } from 'hooks/useToggleWidget'
import {setToggleWidget} from "store/widget";
import {useAppContext} from "hooks/useAppContext";

export const useConfigureChat = () => {
  const dispatch = useDispatch()
  const chatId = useSelector(selectSessionId)

  useEffect(() => {
      if (!chatId) {
        getGuestChat()
          .catch(console.error)
          .then(() => {
            dispatch(setWelcomeMessage({}))
          })
      }
  
  })
}

export const WidgetLayout = () => {
  const { isChatOpenByDefault } = useAppContext()
  const dispatch = useDispatch()
  const toggleWidget = useToggleWidget()
  const botMode = useGetBotMode()
  
  const [isPreview, setIsPreview] = useState(false)
  useConfigureChat()
  
  useEffect(() => {
    if(isChatOpenByDefault) {
      dispatch(setToggleWidget(isChatOpenByDefault));
    }
  }, []);
 
  useEffect(() => {
    const getPreviewParameter = () => {
      try {
        const urlObject = new URL(window.location.href);
        const previewValue = urlObject.searchParams.get('preview');
        return previewValue === 'true';
      } catch (error) {
        console.error('Invalid URL:', error);
        return false;
      }
    };
    
    const preview = getPreviewParameter();
    setIsPreview(preview)
    preview && dispatch(setToggleWidget(preview))
  }, []);

  if (botMode === 'app') {
    return (
      <div
        className={clsx(
          'tw-xs:w-full tw-fixed tw-bottom-1 tw-flex tw-h-[99vh] tw-w-[100vw] tw-flex-col tw-rounded-[1.8rem] tw-mt-3 tw-font-lato',
        )}
        key="widget"
        style={{
          zIndex: 9999999,
        }}
      >
        <WidgetMessages botMode={botMode} />
        <WidgetKeypad botMode={botMode} />
      </div>
    )
  }

  return (
    <AnimatePresence>
      {toggleWidget && (
        <motion.div
          className={clsx(
            // eslint-disable-next-line max-len
            'tw-ring-black-5 tw-xs:right-0 tw-xs:w-full tw-fixed tw-bottom-5 tw-right-5 tw-z-50 tw-flex tw-w-[400px]  tw-max-w-[90vw] tw-flex-col tw-rounded-[1.8rem] tw-bg-white tw-font-lato tw-shadow-lg',
            `tw-h-[40rem]`,
          )}
          initial={isPreview ? { y: -60 } : {}}
          animate={{ y: -60 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'spring', stiffness: 100 }}
          key="widget"
          style={{
            zIndex: 9999999,
          }}
        >
          <WidgetHeader />
          <WidgetMessages botMode={botMode} />
          <WidgetKeypad botMode={botMode} />
        </motion.div>
      )}
      <WidgetLauncher />
    </AnimatePresence>
  )
}
