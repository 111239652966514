import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { WidgetProps } from "types/widget";
import { Widget } from "components/Widget";
import { library } from "@fortawesome/fontawesome-svg-core";
import { API_URL } from "./config";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { selectAgentId } from "store/messages";
import { store } from "./store";
import axios from "axios";

const PRIMARY_COLOR = "#7C3AED";
const SECONDARY_COLOR = "rgb(50,66,130)";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
library.add(fas);

export const widgetProps: WidgetProps = {
  serverApiUrl: API_URL,
  userId: undefined,
  initialPayload: "",
  logo: "/icons/assistme.svg",
  botAvatar: "/icons/bot.svg",
  widgetColor: PRIMARY_COLOR,
  textColor: "white",
  userMsgBackgroundColor: PRIMARY_COLOR,
  botTitle: "AssistMe",
  botSubTitle: "",
  botFirstMessage: 'Hi, how can I help you?',
  chatIcon: '',
  isChatOpenByDefault: false,
  botMsgBackgroundColor: "#f3f4f6",
  chatHeaderCss: {
    textColor: "#000",
    backgroundColor: "#FFF",
    enableBotAvatarBorder: 0,
    avatarStyle: {
      borderRadius: "8px",
    },
  },
  chatHeaderTextColor: "#000",
  botMsgColor: "#4b5563",
  userMsgColor: "#FFF",
  buttonsCss: {
    color: "#FFF",
    backgroundColor: "rgba(0,92,169,0.9)",
    borderColor: "#7C3AED",
    borderWidth: "0",
    borderRadius: "999px",
    hoverBackgroundColor: "rgba(0,92,169,1)",
    hoverColor: "#FFF",
    hoverborderWidth: "0px",
    enableHover: true,
  },
  btnColor: PRIMARY_COLOR,
  theme: {
    colors: {
      primary: PRIMARY_COLOR,
      grey: "#4b5563",
      secondary: SECONDARY_COLOR,
      red: "#e3342f",
      green: "#38c172",
    },
  },
};

async function fetchAgent() {
  const state = store.getState();
  console.log(state, 'state1')
  const agentId = selectAgentId(state);
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/agents/${agentId}`
  );
  
  const agent = response.data;
  // eslint-disable-next-line no-nested-ternary
  agent?.config?.name
    ? (widgetProps.botTitle = agent.config.name)
    : agent.name
      ? (widgetProps.botTitle = agent.name)
      : (widgetProps.botTitle = "AssistMe");
  agent?.config?.motto
    && (widgetProps.botSubTitle = agent.config.motto)
  agent?.config?.image
    ? ((widgetProps.botAvatar = agent.config.image), (widgetProps.logo = agent.config.image))
    : (widgetProps.botAvatar = "/icons/bot.svg");
  agent?.config?.color
    ? ((widgetProps.theme.colors.primary = agent.config.color),
      (widgetProps.btnColor = agent.config.color),
      (widgetProps.userMsgBackgroundColor = agent.config.color),
      (widgetProps.widgetColor = agent.config.color))
    : (widgetProps.theme.colors.primary = PRIMARY_COLOR);
  agent?.config?.text
    ? (widgetProps.textColor = agent.config.text)
    : (widgetProps.textColor = "#FFF");
  agent?.config?.background
    ? (widgetProps.chatHeaderCss.backgroundColor = agent.config.background)
    : (widgetProps.chatHeaderCss.backgroundColor = "#FFF");
  agent?.config?.firstMessage
    ? (widgetProps.botFirstMessage = agent.config.firstMessage)
    : (widgetProps.botFirstMessage = 'Hi, how can I help you?');
  agent?.config?.chatIcon
    && (widgetProps.chatIcon = agent.config.chatIcon)
  agent?.config?.isChatOpenByDefault
    ? (widgetProps.isChatOpenByDefault = agent.config.isChatOpenByDefault)
    : (widgetProps.isChatOpenByDefault = false)

  root.render(
    <React.StrictMode>
      <Widget {...widgetProps} />
    </React.StrictMode>
  );
}

function applyTheme() {
  const urlParams = new URLSearchParams(window.location.search);
  const theme = urlParams.get('theme');
  
  if (theme === 'dark') {
    document.body.classList.add('dark-theme');
  } else {
    document.body.classList.remove('dark-theme');
  }
}

fetchAgent();
applyTheme();
