import { ChatRole } from 'types/index'

type State = {
  userId: string | null
  userToken: string | null
  chatRole: ChatRole | null,
  agentId: string | null,
  sessionId: string | null,
}

export const getUserProps = (): State => {
  const searchParams = new URLSearchParams(window.location?.search)
  const userId = searchParams.get('userId') //Optional
  const agentId = searchParams.get('agentId') //Required
  const userToken = searchParams.get('token') //Public Key
  const chatRole = searchParams.get('chatRole') //Optional
  const sessionId = searchParams.get('sessionId')
  return {
    userId,
    userToken,
    chatRole: chatRole as State['chatRole'],
    agentId,
    sessionId
  }
}
